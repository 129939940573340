import styled from 'styled-components';

const OutterContainer = styled.div`
  padding: 80px 0px;
  @media only screen and (max-width: 501px) {
    padding: 40px 0px;
  }
  h1 {
      font-size: 38px;
      line-height: 38px;
      font-weight: bold;
    }
    h2 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
    }
    h2 {
      font-size: 14px;
      line-height: 14px;
      font-weight: 300;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
`;

export default OutterContainer;
