import React from "react";
import { navigate } from "gatsby-link";

//typings

// components
import OutterContainer from "../../Containers/OutterContainer";
import InnerContainer from "../../Containers/InnerContainer";
import WhitesmokeContainer from "../../Containers/WhitesmokeContainer";
import SpacedButton from "../../Buttons/SpacedButton";
import DateSet from "../../Scheduling/DateSet";

interface Props {
  id: string;
  onBack: () => void;
  services: string[];
}

const NormalReschedule = (props: Props): JSX.Element => {
  return (
    <>
      <InnerContainer>
      <DateSet appointmentId={props.id} services={props.services} />
        <WhitesmokeContainer>
          <SpacedButton variant="outlined" color="secondary" onClick={props.onBack}>
            Volver
          </SpacedButton>
        </WhitesmokeContainer>
      </InnerContainer>
      </>
  )
}

export default NormalReschedule;
