import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";

// typings

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import OutterContainer from "../../../components/Containers/OutterContainer";
import InnerContainer from "../../../components/Containers/InnerContainer";
import Button from "@material-ui/core/Button";
import SpacedButton from "../../../components/Buttons/SpacedButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SuperadminReschedule from "../../../components/Appointments/Reschedule/SuperadminReschedule";
import NormalReschedule from "../../../components/Appointments/Reschedule/NormalReschedule";
import { PageProps } from "gatsby";
import LabService from "../../../utils/interfaces/LabServices";

const SelectorContainer = styled.div`
  background: whitesmoke;
  border-radius: 1rem;
  margin: 0 auto;
  max-width: 20rem;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  id: string;
  location: {
    state: {
      services: string[];
    } & PageProps["location"];
  };
}

const Reschedule = (props: Props): JSX.Element => {
  const [selected, setSelected] = useState<"select" | "superadmin" | "normal">(
    "select"
  );

  return (
    <PrivateRoute>
      <OutterContainer>
        <InnerContainer>
          {selected === "select" && (
            <SelectorContainer>
              <h2>Tipo de re-agendamiento:</h2>
              <ButtonGroup
                variant="contained"
                size="large"
                color="primary"
                aria-label="large contained primary button group"
              >
                <Button
                  className="bg-examedi-blue-strong"
                  value="normal"
                  onClick={() => {
                    setSelected("normal");
                  }}
                >
                  Normal
                </Button>
                <Button
                  className="bg-examedi-blue-strong"
                  value="superadmin"
                  onClick={() => {
                    setSelected("superadmin");
                  }}
                >
                  Super-admin
                </Button>
              </ButtonGroup>
              <SpacedButton
                margin="2rem 0rem 0rem 0rem"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  navigate(`/appointment/${props.id}/`);
                }}
              >
                Volver
              </SpacedButton>
            </SelectorContainer>
          )}
          {selected === "normal" && (
            <NormalReschedule
              id={props.id}
              services={props.location.state.services}
              onBack={() => {
                setSelected("select");
              }}
            />
          )}
          {selected === "superadmin" && (
            <SuperadminReschedule
              id={props.id}
              onBack={() => {
                setSelected("select");
              }}
            />
          )}
        </InnerContainer>
      </OutterContainer>
    </PrivateRoute>
  );
};

export default Reschedule;
