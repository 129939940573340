import api from "../api";
import { AxiosResponse } from "axios";

const baseUrl = "nurse-blocktables/timeblocks";
const blockedBaseUrl = "nurse-blocktables/blocked-timeblocks";

interface FetchAllTimeBlocksParams {
  filter_country?:
    | { nurse_id: string }
    | { laboratory_id: string }
    | { country_code: any }
    | { lab_service_id: string };
}

/* Changing structure to something more web-app alike */
class TimeblockService {
  fetchAllTimeBlocks = (
    filter_country: FetchAllTimeBlocksParams
  ): Promise<AxiosResponse<any>> => {
    let config: object = { params: filter_country };
    if (!filter_country) config = {};
    return api.get(`${baseUrl}/`, config);
  };

  fetchNurseTimeBlocks = (id: string = ""): Promise<AxiosResponse<any>> => {
    if (!id) {
      return api.get(`${baseUrl}/nurse_timeblocks/`);
    }
    return api.get(`${baseUrl}/nurse_timeblocks/`, {
      params: { nurse_id: id },
    });
  };

  assignTimeBlock = (id: string, data: object): Promise<AxiosResponse<any>> => {
    return api.post(`${baseUrl}/${id}/assign_timeblock/`, (data = data));
  };

  unassignTimeBlock = (
    id: string,
    data: object
  ): Promise<AxiosResponse<any>> => {
    return api.post(`${baseUrl}/${id}/unassign_timeblock/`, (data = data));
  };

  blockTimeBlock = (id: string, data: object): Promise<AxiosResponse<any>> => {
    return api.post(`${baseUrl}/${id}/block/`, (data = data));
  };

  unblockTimeBlock = (
    id: string,
    data: object
  ): Promise<AxiosResponse<any>> => {
    return api.post(`${blockedBaseUrl}/${id}/unblock/`, (data = data));
  };
}

const timeBlockService = new TimeblockService();

export default timeBlockService;
