// this component is a copy-paste from web-app's RappiSelectionDate
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import useAssistedSchedulingState from "../../stores/AssistedSchedulingStore";
import { fetchHelper } from "../../utils/api/v1/fetchHelper";
import classNames from "classnames";
import * as _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import availabilityService from "../../utils/api/v1/availabilityService";
import Swal from "sweetalert2";
import { navigate } from "gatsby-link";

import { Input } from "reactstrap";
import Flex from "../Containers/Flex";
import {
  RappiDateWrap,
  HoverFormGroup,
  CustomLabel,
  TimeslotOptions,
  SelectedDayLabel,
  DayLabelCell,
  DayLabelRow,
  DayShorthand,
  DayFull,
} from "../../assets/styles/DateSelector";
import Fab from "@material-ui/core/Fab";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
moment.locale("es");

const DateSet = ({ appointmentId, services }) => {
  const [availabilities, setAvailabilities] = useState(null);
  const [noAvailability, setNoAvailability] = useState(true);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState({
    begin_date: null,
    work_period_max_lateness: null,
  });

  const [selectedParsedDate, setSelectedParsedDate] = useState(null);
  const [days, setDays] = useState(null);
  const [loading, setLoading] = useState(true);
  const [setSelectedDayState, setSelectedSlot] = useAssistedSchedulingState(
    (state) => [state.setSelectedDay, state.setSelectedSlot]
  );

  const nextStep = () => {
    navigate(`/appointment/${appointmentId}`);
  };

  const handleContinue = async () => {
    if (!selectedDay || !selectedTimeslot.begin_date) {
      Swal.fire({
        title: "Error",
        text: "Debe seleccionar una fecha y hora",
        icon: "error",
      });
      return;
    }
    const data = {
      begin_date: `${selectedDay} ${selectedTimeslot["begin_date"]}`,
      end_date: `${selectedDay} ${selectedTimeslot["work_period_max_lateness"]}`,
      rescheduling: true,
      services_ids: services,
    };

    await fetchHelper(setLoading, async () => {
      await availabilityService.setDate(appointmentId, data);
      setSelectedDayState(selectedDay);
      setSelectedSlot(selectedTimeslot);
      nextStep();
    });
  };

  useEffect(() => {
    const fetchAvailabilities = async () => {
      fetchHelper(setLoading, async () => {
        const fetchedAvailabilities =
          await availabilityService.retrieveAvailabilitiesV2(
            appointmentId,
            services,
          );
        const availabilityArray =
          fetchedAvailabilities.data.block_based_availability;

        if (_.isEmpty(availabilityArray)) {
          setNoAvailability(true);
        } else {
          setNoAvailability(false);
          const dateKeys = Object.keys(availabilityArray).sort();

          // Parse date keys into displayable formats
          const parsedDateKeys = dateKeys.map((item) => {
            const momentDate = moment(item, "YYYY-MM-DD");
            return {
              key: item,
              date: momentDate,
              dayLabel: momentDate.format("dddd"),
              month: momentDate.format("MMMM"),
              dayNum: momentDate.get("date"),
            };
          });

          const firstTimeslot = availabilityArray[`${dateKeys[0]}`][0];

          setSelectedTimeslot({
            begin_date: firstTimeslot[0],
            work_period_max_lateness: firstTimeslot[1],
          });
          setAvailabilities(availabilityArray);
          setDays(parsedDateKeys);
          setSelectedParsedDate(parsedDateKeys[0]);
          setSelectedDay(parsedDateKeys[0]["key"]);
        }
      });
    };

    fetchAvailabilities();
  }, [appointmentId]);

  return loading ? (
    <>
      <Flex justify="center" align="center">
        <CircularProgress color="inherit" />
        <span>Cargando disponibilidad</span>
      </Flex>
    </>
  ) : (
    <>
      <RappiDateWrap variant="outlined">
        <>
          {noAvailability ? (
            <div style={{ padding: "0px 20px" }}>
              <Flex justify="center" align="center">
                <h1>¡Lo Sentimos!</h1>
                <h2 style={{ width: "clamp(200px, 100%, 600px)" }}>
                  Por el momento no llegamos a esa comuna 😢
                </h2>
              </Flex>
            </div>
          ) : (
            <>
              <div>
                <h3>Fecha y hora</h3>
                <DayLabelRow>
                  {days.map((item, i) => {
                    return (
                      <DayLabelCell
                        key={i}
                        className={classNames({
                          "active-cell": item["key"] === selectedDay,
                        })}
                        onClick={() => {
                          setSelectedDay(item["key"]);
                          setSelectedParsedDate(item);
                          const timestamp = availabilities[item["key"]][0];
                          const parsedTimeslot = {
                            begin_date: timestamp[0],
                            work_period_max_lateness: timestamp[1],
                          };

                          setSelectedTimeslot(parsedTimeslot);
                        }}
                      >
                        <DayShorthand>{item.dayLabel.slice(0, 3)}</DayShorthand>
                        <DayFull>
                          {item.month.slice(0, 3)}&nbsp;{item.dayNum}
                        </DayFull>
                      </DayLabelCell>
                    );
                  })}
                </DayLabelRow>
              </div>
              <SelectedDayLabel>
                <p>
                  <span style={{ textTransform: "capitalize" }}>
                    {selectedParsedDate.dayLabel}
                  </span>
                  , {selectedParsedDate.dayNum} de{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {selectedParsedDate.month}
                  </span>
                </p>
              </SelectedDayLabel>
              <TimeslotOptions>
                {availabilities[`${selectedDay}`].map((item, i) => {
                  return (
                    <HoverFormGroup
                      check
                      key={i}
                      style={{
                        border: "1px solid whitesmoke",
                        width: "100%",
                      }}
                    >
                      <CustomLabel check>
                        <Input
                          type="radio"
                          name="radio1"
                          checked={selectedTimeslot["begin_date"] === item[0]}
                          onChange={() => {
                            const parsedTimeslot = {
                              begin_date: item[0],
                              work_period_max_lateness: item[1],
                            };
                            setSelectedTimeslot(parsedTimeslot);
                          }}
                        />
                        <p>{`${item[0].slice(0, -3)} hrs - ${item[1].slice(
                          0,
                          -3
                        )} hrs`}</p>
                      </CustomLabel>
                    </HoverFormGroup>
                  );
                })}
              </TimeslotOptions>
            </>
          )}
        </>
      </RappiDateWrap>
      <Flex align="center" justify="center" margin="2rem 0 0 0">
        <Fab
          variant="extended"
          color="primary"
          onClick={() => handleContinue()}
        >
          Guardar
        </Fab>
      </Flex>
    </>
  );
};

export default DateSet;
