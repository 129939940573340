import { ValidateDiscountCodePayload } from "../interfaces/Referrals";
import { Locale } from "../interfaces/Locale";

export const COUNTRIES = ["Chile", "México"];

export const countriesNoTilde = ["Chile", "Mexico"];

export const COUNTRY_CODES: Locale[] = ["cl", "mx"];

export const CODE_TO_COUNTRY: Record<Locale, string> = { cl: "Chile", mx: "México" };

export const COUNTRY_TO_CODE = { Chile: "cl", México: "mx" };

export const CODE_TO_COUNTRY_NO_TILDE = { cl: "Chile", mx: "Mexico" };

export const TIMEZONE_TO_COUNTRY = {
  "America/Santiago": "Hora de Chile",
  "America/Mexico_City": "Hora de México",
}

export const COUNTRY_TO_TIMEZONE = {
  Chile: "America/Santiago",
  México: "America/Mexico_City",
}

export const DEFAULT_TIMEZONE = "America/Santiago";

export const MasvidaUnavailableName = [
  "Albúminas en Sangre",
  "Anticuerpos y antígenos virales",
  "Curva de Glucosa",
  "Curva de Insulina",
  "Electrocardiograma",
  "Kinesiología",
  "PCR Express",
  "PCR Normal",
  "Test Rápido de Antígenos",
  "Vacunas",
  "VIH",
  "Vitamina B",
  "Vitamina B12",
  "Vitamina D",
];

export const MasvidaUnavailableType = ["covid", "vaccine", "kinesiology"];

// ingresar a base de datos para encontrar string
export const MasvidaUnavailableCheck = [
  "Vitamina ",
  "Curva de",
  "VIH",
  "HIV",
  "albúmina",
  "Electrocardiograma",
  "Antígenos virales",
  "Anticuerpos virales",
];

export const discountReasonsText: Record<
  ValidateDiscountCodePayload["data"]["reason"],
  string
> = {
  0: "Inválido",
  1: "Expirado",
  2: "Sin stock",
  3: "Aplicado",
  4: "Aplicado",
  5: "Inválido",
  6: "Inválido",
  7: "Inválido",
  8: "Aplicar",
};

export const SUBSCRIPTIONS_CATEGORIES = ["Todas", "exanutri"];
